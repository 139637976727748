import React from 'react';
import ReactDOM from 'react-dom';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
// import { ResetCSS } from '@polydex/uikit';

import App from './App';
import ApplicationUpdater from './state/application/updater';
import ListsUpdater from './state/lists/updater';
import MulticallUpdater from './state/multicall/updater';
import TransactionUpdater from './state/transactions/updater';
import UserUpdater from './state/user/updater';
import SwapUpdater from './state/swap/updater';
// import GlobalStyle from './style/Global';
import Providers from './Providers';

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      storage: 'none',
      storeGac: false,
    },
  });
  ReactGA.set({
    anonymizeIp: true,
    customBrowserType: !isMobile
      ? 'desktop'
      : 'web3' in window || 'ethereum' in window
      ? 'mobileWeb3'
      : 'mobileRegular',
  });
} else {
  ReactGA.initialize('test', { testMode: true, debug: true });
}

window.addEventListener('error', (error) => {
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true,
  });
});

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <>
        <SwapUpdater />
        <ListsUpdater />
        <UserUpdater />
        <ApplicationUpdater />
        <TransactionUpdater />
        <MulticallUpdater />
      </>
      {/* <ResetCSS />
      <GlobalStyle /> */}
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);
