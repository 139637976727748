import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import masterChefABI from 'config/abi/masterchef.json'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress, getWmaticAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getSouschefV2Contract } from 'utils/contractHelpers'
import { simpleRpcProvider } from '../../utils/providers'
import { ethers } from 'ethers'

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 0)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndBlock',
    }
  })

  // const starts = await multicall(sousChefABI, callsStartBlock)
  // const ends = await multicall(sousChefABI, callsEndBlock)

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const startBlock = 0//starts[index]
    const endBlock = 1000000000//ends[index]
    return {
      sousId: cakePoolConfig.sousId,
      id: cakePoolConfig.id,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchPoolsTotalStaking = async () => {
  const calls = poolsConfig.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'poolInfo',
      params: [poolConfig.sousId],
    }
  })

  const poolsTotalStaked = await multicall(masterChefABI, calls) || []
  return [
    ...poolsConfig.map((p, index) => ({
      id: p.id,
      sousId: p.sousId,
      totalStaked: poolsTotalStaked[index] ? poolsTotalStaked[index].totalLpSupply.toString() : '0',
    }))
  ]
}

export const fetchPoolStakingLimit = async (sousId: number): Promise<BigNumber> => {
  try {
    const sousContract = getSouschefV2Contract(sousId)
    const stakingLimit = await sousContract.poolLimitPerUser()
    return new BigNumber(stakingLimit)
  } catch (error) {
    return BIG_ZERO
  }
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
): Promise<{ [key: string]: BigNumber }> => {
  const validPools = poolsConfig
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.sousId))

  // Get the staking limit for each valid pool
  // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
  const stakingLimitPromises = validPools.map((validPool) => fetchPoolStakingLimit(validPool.sousId))
  const stakingLimits = await Promise.all(stakingLimitPromises)

  return stakingLimits.reduce((accum, stakingLimit, index) => {
    return {
      ...accum,
      [validPools[index].sousId]: stakingLimit,
    }
  }, {})
}

//FIXME: Consider remove web3NoAccount
export const fetchMasterPoolInfo = async (chefAddress: string) => {
  const masterChefContract = new ethers.Contract(chefAddress, masterChefABI, simpleRpcProvider)

  const [poolInfo, totalAllocPoint] = await Promise.all([
    masterChefContract.poolInfo('0'),
    masterChefContract.totalAllocPoint(),
  ])
  return {
    poolWeight: poolInfo.allocPoint/totalAllocPoint,
  }
}
