import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  // {
  //   id: 4,
  //   pid: 4,
  //   lpSymbol: 'VSHARE-CRO',
  //   lpAddresses: {
  //     25: '0xD2219106310E46D7FD308c0eC9d9FCc2d2c8a9B5',
  //   },
  //   token: tokens.krx,
  //   quoteToken: tokens.cro,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 0.5h lockup',
  //   startReward: 1637424000,
  //   multiplier: '15X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0xD2219106310E46D7FD308c0eC9d9FCc2d2c8a9B5`,
  //   enableEmergencyWithdraw: true
  // },
  // {
  //   id: 5,
  //   pid: 5,
  //   lpSymbol: 'VSHARE-USDC',
  //   lpAddresses: {
  //     25: '0x9504a7cEd300B2C79e64FC63f368fC27011Fe916',
  //   },
  //   token: tokens.krx,
  //   quoteToken: tokens.usdc,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 0.5h lockup',
  //   startReward: 1637424000,
  //   multiplier: '10X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0x9504a7cEd300B2C79e64FC63f368fC27011Fe916`,
  //   enableEmergencyWithdraw: true
  // },
  // {
  //   id: 6,
  //   pid: 6,
  //   lpSymbol: 'CRO-USDC',
  //   lpAddresses: {
  //     25: '0x9dc0Bd33FF380c59409605BB5905D271d4fC6b16',
  //   },
  //   token: tokens.cro,
  //   quoteToken: tokens.usdc,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '2X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0x9dc0Bd33FF380c59409605BB5905D271d4fC6b16`,
  //   enableEmergencyWithdraw: true
  // },
  // {
  //   id: 7,
  //   pid: 7,
  //   lpSymbol: 'WBTC-USDC',
  //   lpAddresses: {
  //     25: '0x4108d493cd519e786488dfcb7c8867ab2a1396c3',
  //   },
  //   token: tokens.wbtc,
  //   quoteToken: tokens.usdc,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '2X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0x4108d493cd519e786488dfcb7c8867ab2a1396c3`,
  //   enableEmergencyWithdraw: true

  // },
  // {
  //   id: 8,
  //   pid: 8,
  //   lpSymbol: 'WETH-USDC',
  //   lpAddresses: {
  //     25: '0x5803b42e29def0d7a14d29107ca7ea65bbfa833f',
  //   },
  //   token: tokens.weth,
  //   quoteToken: tokens.usdc,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '2X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0x5803b42e29def0d7a14d29107ca7ea65bbfa833f`,
  //   enableEmergencyWithdraw: true
  // },
  // {
  //   id: 9,
  //   pid: 9,
  //   lpSymbol: 'USDT-USDC',
  //   lpAddresses: {
  //     25: '0xa56c9ba27365808bfa7a61b1cc7cc6969a4f4a7b',
  //   },
  //   token: tokens.usdt,
  //   quoteToken: tokens.usdc,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '1X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0xa56c9ba27365808bfa7a61b1cc7cc6969a4f4a7b`,
  //   enableEmergencyWithdraw: true
  // },
  // {
  //   id: 10,
  //   pid: 10,
  //   lpSymbol: 'DAI-USDC',
  //   lpAddresses: {
  //     25: '0x69fa1d41afdb5f1a826c5e89d7bdd1fcbf0aff23',
  //   },
  //   token: tokens.dai,
  //   quoteToken: tokens.usdc,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '1X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0x69fa1d41afdb5f1a826c5e89d7bdd1fcbf0aff23`,
  //   enableEmergencyWithdraw: true
  // },
  // {
  //   id: 11,
  //   pid: 11,
  //   lpSymbol: 'BUSD-USDC',
  //   lpAddresses: {
  //     25: '0xdaf83b31bac15778e6ae2731d4c7391d13464f07',
  //   },
  //   token: tokens.busd,
  //   quoteToken: tokens.usdc,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '1X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0xdaf83b31bac15778e6ae2731d4c7391d13464f07`,
  //   enableEmergencyWithdraw: true

  // },
  // {
  //   id: 12,
  //   pid: 12,
  //   lpSymbol: 'WBTC-CRO',
  //   lpAddresses: {
  //     25: '0x6b4f183ea4b2d30415d86b3e8c0bcd9789f5811a',
  //   },
  //   token: tokens.wbtc,
  //   quoteToken: tokens.cro,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '1X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0x6b4f183ea4b2d30415d86b3e8c0bcd9789f5811a`,
  //   enableEmergencyWithdraw: true

  // },
  // {
  //   id: 13,
  //   pid: 13,
  //   lpSymbol: 'WETH-CRO',
  //   lpAddresses: {
  //     25: '0x0ea2e0ac750dca7fdb5111e17ce6ac30a0844873',
  //   },
  //   token: tokens.weth,
  //   quoteToken: tokens.cro,
  //   masterChefAddress: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
  //   platform: 'OldKryptoDex',
  //   warning: '⚠ 70% rewards locked',
  //   tag: '🔒 1h lockup',
  //   startReward: 1637416800,
  //   multiplier: '1X',
  //   addLiquidityUrl: `https://www.v3s.finance/add/0x0ea2e0ac750dca7fdb5111e17ce6ac30a0844873`,
  //   enableEmergencyWithdraw: true
  // },

  //new farm
  {
    id: 24,
    pid: 4,
    lpSymbol: 'VSHARE-CRO',
    lpAddresses: {
      25: '0xD2219106310E46D7FD308c0eC9d9FCc2d2c8a9B5',
    },
    token: tokens.krx,
    quoteToken: tokens.cro,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 0.5h lockup',
    startReward: 1637452800,
    addLiquidityUrl: `https://www.v3s.finance/add/0xD2219106310E46D7FD308c0eC9d9FCc2d2c8a9B5`
  },
  {
    id: 25,
    pid: 5,
    lpSymbol: 'VSHARE-USDC',
    lpAddresses: {
      25: '0x9504a7cEd300B2C79e64FC63f368fC27011Fe916',
    },
    token: tokens.krx,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 0.5h lockup',
    startReward: 1637452800,
    addLiquidityUrl: `https://www.v3s.finance/add/0x9504a7cEd300B2C79e64FC63f368fC27011Fe916`
  },
  {
    id: 34,
    pid: 14,
    lpSymbol: 'VSHARE-VVS',
    lpAddresses: {
      25: '0xF9F9065C6F281377a173c0A6BAC8eE982A0AAf76',
    },
    token: tokens.weth,
    quoteToken: tokens.vvs,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637481600,
    addLiquidityUrl: `https://www.v3s.finance/add/0xF9F9065C6F281377a173c0A6BAC8eE982A0AAf76`
  },
  {
    id: 26,
    pid: 6,
    lpSymbol: 'CRO-USDC',
    lpAddresses: {
      25: '0x9dc0Bd33FF380c59409605BB5905D271d4fC6b16',
    },
    token: tokens.cro,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0x9dc0Bd33FF380c59409605BB5905D271d4fC6b16`
  },
  {
    id: 27,
    pid: 7,
    lpSymbol: 'WBTC-USDC',
    lpAddresses: {
      25: '0x4108d493cd519e786488dfcb7c8867ab2a1396c3',
    },
    token: tokens.wbtc,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0x4108d493cd519e786488dfcb7c8867ab2a1396c3`

  },
  {
    id: 28,
    pid: 8,
    lpSymbol: 'WETH-USDC',
    lpAddresses: {
      25: '0x5803b42e29def0d7a14d29107ca7ea65bbfa833f',
    },
    token: tokens.weth,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0x5803b42e29def0d7a14d29107ca7ea65bbfa833f`

  },
  {
    id: 29,
    pid: 9,
    lpSymbol: 'USDT-USDC',
    lpAddresses: {
      25: '0xa56c9ba27365808bfa7a61b1cc7cc6969a4f4a7b',
    },
    token: tokens.usdt,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0xa56c9ba27365808bfa7a61b1cc7cc6969a4f4a7b`

  },
  {
    id: 30,
    pid: 10,
    lpSymbol: 'DAI-USDC',
    lpAddresses: {
      25: '0x69fa1d41afdb5f1a826c5e89d7bdd1fcbf0aff23',
    },
    token: tokens.dai,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0x69fa1d41afdb5f1a826c5e89d7bdd1fcbf0aff23`

  },
  {
    id: 31,
    pid: 11,
    lpSymbol: 'BUSD-USDC',
    lpAddresses: {
      25: '0xdaf83b31bac15778e6ae2731d4c7391d13464f07',
    },
    token: tokens.busd,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0xdaf83b31bac15778e6ae2731d4c7391d13464f07`

  },
  {
    id: 32,
    pid: 12,
    lpSymbol: 'WBTC-CRO',
    lpAddresses: {
      25: '0x6b4f183ea4b2d30415d86b3e8c0bcd9789f5811a',
    },
    token: tokens.wbtc,
    quoteToken: tokens.cro,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0x6b4f183ea4b2d30415d86b3e8c0bcd9789f5811a`

  },
  {
    id: 33,
    pid: 13,
    lpSymbol: 'WETH-CRO',
    lpAddresses: {
      25: '0x0ea2e0ac750dca7fdb5111e17ce6ac30a0844873',
    },
    token: tokens.weth,
    quoteToken: tokens.cro,
    masterChefAddress: '0x53cE820Ed109D67746a86b55713E30252275c127',
    platform: 'KryptoDex',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    startReward: 1637431200,
    addLiquidityUrl: `https://www.v3s.finance/add/0x0ea2e0ac750dca7fdb5111e17ce6ac30a0844873`
  },
  //start from id 35
]

export default farms
