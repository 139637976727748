import { createGlobalStyle } from 'styled-components';
import { PancakeTheme } from '@polydex/uikit/dist/theme';

import BgImg from 'assets/images/page_bg.png';

declare module 'styled-components' {
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${BgImg});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
  }

  * {
    font-family: "Tomorrow",sans-serif !important;
    
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .MuiToolbar-regular {
    min-height: unset!important;
  }

  .MuiIconButton-colorSecondary {
    color: #fff !important;
  }

  .MuiDrawer-paper {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }

  .MuiDrawer-paper  .MuiIconButton-root {
    color: #000 !important;
  }

  .MuiDrawer-paper .MuiDivider-root {
    background: transparent;
  }

  .MuiDrawer-paper .MuiListItem-root {
    color: #003a70;
  }
`;

export default GlobalStyle;
