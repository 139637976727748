const tokens = {
  matic: {
    symbol: 'MATIC',
    address: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      80001: '',
    },
  },
  krx: {
    symbol: 'KRX',
    address: {
      25: '0xF0681BB7088Ac68A62909929554Aa22ad89a21fB',
    },
    decimals: 18,
    projectLink: 'https://v3s.finance/',
  },
  cro: {
    symbol: 'CRO',
    address: {
      25: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
    },
    decimals: 18,
  },
  usdc: {
    symbol: 'USDC',
    address: {
      25: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
    },
    decimals: 6,
  },
  usdt: {
    symbol: 'USDT',
    address: {
      25: '0x66e428c3f67a68878562e79A0234c1F83c208770',
    },
    decimals: 6,
  },
  busd: {
    symbol: 'BUSD',
    address: {
      25: '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
    },
    decimals: 18,
  },
  dai: {
    symbol: 'DAI',
    address: {
      25: '0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
    },
    decimals: 18,
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      25: '0x062E66477Faf219F25D27dCED647BF57C3107d52',
    },
    decimals: 8,
  },
  weth: {
    symbol: 'WETH',
    address: {
      25: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
    },
    decimals: 18,
  },
  vvs: {
    symbol: 'VVS',
    address: {
      25: '0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03',
    },
    decimals: 18,
  },
  shib: {
    symbol: 'SHIB',
    address: {
      25: '0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee',
    },
    decimals: 18,
  },
  cusd: {
    symbol: 'cUSD',
    address: {
      25: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
    },
    decimals: 18,
  },
  ceur: {
    symbol: 'cEUR',
    address: {
      25: '0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
    },
    decimals: 18,
  },

  fplx: {
    symbol: 'fKRX',
    address: {
      137: '0x28151Ba48963019ddB509FbB6D5761F3494b3828',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://v3s.finance/',
  },
  wmatic: {
    symbol: 'WMATIC',
    address: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  
  ube: {
    symbol: 'UBE',
    address: {
      25: '0x00Be915B9dCf56a3CBE739D9B9c202ca692409EC',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  sushi: {
    symbol: 'SUSHI',
    address: {
      25: '0xD15EC721C2A896512Ad29C671997DD68f9593226',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  mobi: {
    symbol: 'MOBI',
    address: {
      25: '0x73a210637f6F6B7005512677Ba6B3C96bb4AA44B',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  
  sds: {
    symbol: 'SDS',
    address: {
      137: '0x352db329B707773DD3174859F1047Fb4Fd2030BC',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://safedollar.fi/',
  },

  meeb: {
    symbol: 'MEEB',
    address: {
      137: '0x64aFDF9e28946419E325d801Fb3053d8B8FFdC23',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://meebmaster.com',
  },
  top: {
    symbol: 'TOP',
    address: {
      137: '0x4438da4ef8e791a9964d27742676e6a70beb2514',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://tokenplay.app/',
  },
  tiny: {
    symbol: 'TINY',
    address: {
      137: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://tinyhero.io/',
  },
  owl: {
    symbol: 'OWL',
    address: {
      137: '0x9085B4d52c3e0B8B6F9AF6213E85A433c7D76f19',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://owl.games/',
  },
  duel: {
    symbol: 'DUEL',
    address: {
      137: '0x0000000000000000000000000000000000000000',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://duelverse.io/',
  },
  proton: {
    symbol: 'PROTON',
    address: {
      137: '0x673e064eCb5889Ef163ec3C3eA693b8f74BBC95D',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://owl.games/',
  },
  owltest: {
    symbol: 'OWL',
    address: {
      137: '0x49958E40928F0738e447FAd87B93E1F4Fb445DA7',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://owl.games/',
  },
  xdot: {
    symbol: 'XDOT',
    address: {
      137: '0x49958E40928F0738e447FAd87B93E1F4Fb445DA7',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  mtoken: {
    symbol: 'MToken',
    address: {
      25: '0xd699B0571110cdfa61febFB5f65f3c26e1cfB938',
    },
    decimals: 18,
    projectLink: '',
  },
}

export default tokens
