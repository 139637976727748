import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { isMobile } from '@deficonnect/utils';

import useAuth from 'hooks/useAuth';
import { connectorLocalStorageKey, ConnectorNames, connectorsByName } from 'connector';

const useEagerConnect = () => {
  const { login } = useAuth();
  const { activate, account } = useWeb3React();

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
    const isSupportedConnectorId = Object.values(ConnectorNames).includes(connectorId);
    const isSignOut = localStorage.getItem('signOut');

    if (connectorId && isSupportedConnectorId) {
      login(connectorId);
    } else {
      const injected = connectorsByName[ConnectorNames.Injected];
      injected.isAuthorized().then((isAuthorized: any) => {
        if (isAuthorized) {
          if (!isSignOut) {
            login(ConnectorNames.Injected);
          }
        } else {
          if (isMobile() && window.ethereum) {
            if (!isSignOut) {
              login(ConnectorNames.Injected);
            }
          }
        }
      });
    }
  }, [account, activate, login]);
};

export default useEagerConnect;
