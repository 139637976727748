import { utils } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts'
import { Interface } from '@ethersproject/abi'
import { PoolPairData, Pools, Pool, SubGraphPools, Token } from './types';
import * as bmath from './bmath';

// LEGACY FUNCTION - Keep Input/Output Format
export async function parsePoolDataOnChain(
  pools: any[],
  tokenIn: string,
  tokenOut: string,
  multiAddress: string,
  provider: Web3Provider
): Promise<PoolPairData[]> {
  if (pools.length === 0)
    throw Error('There are no pools with selected tokens');

  const multiAbi = require('./abi/multicall.json');
  const bpoolAbi = require('./abi/bpool.json');

  const multi = new Contract(multiAddress, multiAbi, provider);

  const iface: any = new Interface(bpoolAbi);

  // const promises: Promise<any>[] = [];

  let calls: any[] = [];

  // let poolData: PoolPairData[] = [];
  pools.forEach(p => {
    calls.push([p.id, iface.encodeFunctionData(iface?.getFunction('swapFee'), [tokenIn])]);
    calls.push([p.id, iface.encodeFunctionData(iface?.getFunction('getBalance'), [tokenOut])]);
    calls.push([p.id, iface.encodeFunctionData(iface?.getFunction('getNormalizedWeight'), [tokenIn])]);
    calls.push([p.id, iface.encodeFunctionData(iface?.getFunction('getNormalizedWeight'), [tokenOut])]);
    calls.push([p.id, iface.encodeFunctionData(iface?.getFunction('getSwapFee'), [])]);
  });

  try {
    const [, response] = await multi.aggregate(calls);
    let chunkResponse = [];
    let returnPools: PoolPairData[] = [];
    for (let i = 0; i < response.length; i += 5) {
      let chunk = response.slice(i, i + 5);
      chunkResponse.push(chunk);
    }

    chunkResponse.forEach((r, j) => {
      let obj = {
        id: pools[j].id,
        tokenIn: tokenIn,
        tokenOut: tokenOut,
        balanceIn: bmath.bnum(r[0]),
        balanceOut: bmath.bnum(r[1]),
        weightIn: bmath.bnum(r[2]),
        weightOut: bmath.bnum(r[3]),
        swapFee: bmath.bnum(r[4]),
        collectedFee: bmath.bnum(0),
        version: pools[j].version,
      };
      returnPools.push(obj);
    });

    return returnPools;
  } catch (e) {
    console.error('1.Failure querying onchain balances', { error: e });
    return [];
  }
}

export async function getAllPoolDataOnChain(
  pools: SubGraphPools,
  multiAddress: string,
  provider: Web3Provider,
  bFactoryAddress?: string
): Promise<Pools> {
  if (pools.pools.length === 0)
    throw Error('There are no pools with selected tokens');

  const multiAbi = require('./abi/multicall.json');
  // const bpoolAbi = require('./abi/bpool.json');
  const fpoolV1Abi = require('./abi/fpoolv1.json');
  const fpoolV2Abi = require('./abi/fpoolv2.json');
  // const bfactoryAbi = require('./abi/bfactory.json');

  const multi: any = new Contract(multiAddress, multiAbi, provider);
  // const bPool = new ethers.utils.Interface(bpoolAbi);
  const fpoolV1: any = new Interface(fpoolV1Abi);
  const fpoolV2: any = new Interface(fpoolV2Abi);
  // const bfactory = new ethers.utils.Interface(bfactoryAbi);

  // const promises: Promise<any>[] = [];

  let calls: any[] = [];

  // let encodedCollectedToken = bfactory.functions.collectedToken.encode([]);
  //
  // calls.push([bFactoryAddress, encodedCollectedToken]);

  let encodedSwapFee = fpoolV1.encodeFunctionData(fpoolV1.getFunction('swapFee'), []);
  let encodedCollectedFee = fpoolV1.encodeFunctionData(fpoolV1.getFunction('collectedFee', []))
  let encodedBalance = utils.hexDataSlice(
    utils.keccak256(utils.toUtf8Bytes('getBalance(address)')),
    0,
    4
  );
  let encodedWeight = utils.hexDataSlice(
    utils.keccak256(utils.toUtf8Bytes('getDenormalizedWeight(address)')),
    0,
    4
  );
  let encodedTotalSupply = fpoolV1.encodeFunctionData(fpoolV1.getFunction('totalSupply', []))

  let encodedToken0 = fpoolV2.encodeFunctionData(fpoolV2.getFunction('token0', []));
  let encodedGetSwapFee = fpoolV2.encodeFunctionData(fpoolV2.getFunction('getSwapFee', []));
  let encodedGetReserves = fpoolV2.encodeFunctionData(fpoolV2.getFunction('getReserves', []));
  let encodedTokenWeights = fpoolV2.encodeFunctionData(fpoolV2.getFunction('getTokenWeights', []));

  for (let i = 0; i < pools.pools.length; i++) {
    // for (let i = 0; i < 1; i++) {
    let p = pools.pools[i];

    switch (p.version) {
      case 3001: {
        calls.push([p.id, encodedToken0]);
        calls.push([p.id, encodedGetSwapFee]);
        calls.push([p.id, encodedTotalSupply]);
        calls.push([p.id, encodedGetReserves]);
        calls.push([p.id, encodedTokenWeights]);
        break;
      }
      case 3051:
      case 4051:
      case 5051:
      case 4052: {
        calls.push([p.id, encodedToken0]);
        calls.push([p.id, encodedTotalSupply]);
        calls.push([p.id, encodedGetReserves]);
        break;
      }
      // 2001 || bPool
      default: {
        calls.push([p.id, encodedSwapFee]);
        calls.push([p.id, encodedCollectedFee]);
        calls.push([p.id, encodedTotalSupply]);

        // Checks all tokens for pool
        p.tokens.forEach(token => {
          let paddedAddr = utils
            .hexZeroPad(token.address, 32)
            .replace(`0x`, '');

          calls.push([
            p.id,
            encodedBalance.concat(paddedAddr.replace(`0x`, '')),
          ]);

          calls.push([
            p.id,
            encodedWeight.concat(paddedAddr.replace(`0x`, '')),
          ]);
        });
      }
    }
  }

  try {
    // console.log(`Multicalls: ${calls.length}`);
    const [, response] = await multi.aggregate(calls);

    let j = 0;
    let onChainPools: Pools = { pools: [] };

    // const collectedToken = utils.hexDataSlice(response[j++].toLowerCase(), 12);

    for (let i = 0; i < pools.pools.length; i++) {
      switch (pools.pools[i].version) {
        case 3001: {
          let token0Address = utils.hexDataSlice(
            response[j++].toLowerCase(),
            12
          );
          let tokens: Token[] = [];
          let publicSwap = true;
          if (pools.pools[i].publicSwap === 'false') publicSwap = false;
          let p: Pool = {
            id: pools.pools[i].id,
            swapFee: bmath.scale(bmath.bnum(response[j++]), 14),
            collectedFee: bmath.bnum(0),
            totalWeight: bmath.scale(
              bmath.bnum(pools.pools[i].totalWeight),
              18
            ),
            publicSwap: publicSwap,
            tokens: tokens,
            tokensList: pools.pools[i].tokensList,
            version: pools.pools[i].version,
            totalShares: bmath.bnum(response[j]),
          };
          j++;
          const reserves = [
            utils.hexDataSlice(response[j], 0, 32),
            utils.hexDataSlice(response[j], 32, 64),
          ];
          j++;
          const tokenWeights = [
            utils.hexDataSlice(response[j], 0, 32),
            utils.hexDataSlice(response[j], 32, 64),
          ];
          j++;
          pools.pools[i].tokens.forEach(token => {
            let bal = bmath.bnum(
              token.address === token0Address ? reserves[0] : reserves[1]
            );
            let dW = bmath.scale(
              bmath.bnum(
                token.address === token0Address
                  ? tokenWeights[0]
                  : tokenWeights[1]
              ),
              18
            );
            p.tokens.push({
              id: token.id,
              address: token.address,
              balance: bal,
              decimals: Number(token.decimals),
              symbol: token.symbol,
              denormWeight: dW,
            });
          });
          onChainPools.pools.push(p);
          break;
        }
        case 3051:
        case 4051:
        case 5051:
        case 4052: {
          let token0Address = utils.hexDataSlice(
            response[j++].toLowerCase(),
            12
          );
          let tokens: Token[] = [];
          let publicSwap = true;
          if (pools.pools[i].publicSwap === 'false') publicSwap = false;
          let p: Pool = {
            id: pools.pools[i].id,
            swapFee: bmath.scale(bmath.bnum(pools.pools[i].swapFee), 18),
            collectedFee: bmath.bnum(0),
            totalWeight: bmath.scale(
              bmath.bnum(pools.pools[i].totalWeight),
              18
            ),
            publicSwap: publicSwap,
            tokens: tokens,
            tokensList: pools.pools[i].tokensList,
            version: pools.pools[i].version,
            totalShares: bmath.bnum(response[j]),
          };
          j++;
          const reserves = [
            utils.hexDataSlice(response[j], 0, 32),
            utils.hexDataSlice(response[j], 32, 64),
          ];
          j++;
          pools.pools[i].tokens.forEach(token => {
            let bal = bmath.bnum(
              token.address === token0Address ? reserves[0] : reserves[1]
            );
            let dW = bmath.scale(bmath.bnum(50), 18);
            p.tokens.push({
              id: token.id,
              address: token.address,
              balance: bal,
              decimals: Number(token.decimals),
              symbol: token.symbol,
              denormWeight: dW,
            });
          });
          onChainPools.pools.push(p);
          break;
        }
        default: {
          let tokens: Token[] = [];
          let publicSwap = true;
          if (pools.pools[i].publicSwap === 'false') publicSwap = false;

          const swapFee = bmath.bnum(response[j++]);
          const collectedFee = bmath.bnum(response[j++]);
          // const collectedFee = bmath.bnum(0);
          const totalShares = bmath.bnum(response[j++]);

          let p: Pool = {
            id: pools.pools[i].id,
            swapFee: swapFee,
            collectedFee: collectedFee,
            totalWeight: bmath.scale(
              bmath.bnum(pools.pools[i].totalWeight),
              18
            ),
            publicSwap: publicSwap,
            tokens: tokens,
            tokensList: pools.pools[i].tokensList,
            version: pools.pools[i].version,
            // collectedToken: collectedToken,
            collectedToken: '',
            totalShares,
          };
          // eslint-disable-next-line
          pools.pools[i].tokens.forEach(token => {
            let bal = bmath.bnum(response[j]);
            j++;
            let dW = bmath.bnum(response[j]);
            j++;
            p.tokens.push({
              id: token.id,
              address: token.address,
              balance: bal,
              decimals: Number(token.decimals),
              symbol: token.symbol,
              denormWeight: dW,
            });
          });
          onChainPools.pools.push(p);
        }
      }
    }
    return onChainPools;
  } catch (e) {
    console.error('2.Failure querying onchain balances', { error: e });
    return {} as Pools;
  }
}
