import MetamaskIcon from 'assets/images/metamask-fox.svg';
import WalletConnectIcon from 'assets/images/wallet-connect.svg';
import DefiWalletIcon from 'assets/images/defiwallet.svg';
import { injected, walletconnect, deficonnect } from './connectors';

export enum ConnectorNames {
  Injected = 'Injected',
  Metamask = 'Metamask',
  WalletConnect = 'WalletConnect',
  CDCDefiWallet = 'DeFiWallet',
}

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.Metamask]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.CDCDefiWallet]: deficonnect,
};

export const connectorsConfig = {
  Metamask: {
    title: 'Metamask',
    icon: MetamaskIcon,
    connectorId: ConnectorNames.Metamask,
    priority: 1,
  },
  CryptoCom: {
    title: 'Crypto.com Defi Wallet',
    icon: DefiWalletIcon,
    connectorId: ConnectorNames.CDCDefiWallet,
    priority: 2,
  },

  WalletConnect: {
    title: 'Wallet Connect',
    icon: WalletConnectIcon,
    connectorId: ConnectorNames.WalletConnect,
    priority: 3,
  },
};

export const connectorLocalStorageKey = 'connectorIdv2';
export const walletLocalStorageKey = 'wallet';

export const setupNetwork = async () => {
  const provider: any = window;
  if (provider?.ethereum) {
    const chainId = 25;
    const chainType = 'Mainnet';
    try {
      await provider?.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: `Cronos ${chainType}`,
            nativeCurrency: {
              name: 'CRO',
              symbol: 'CRO',
              decimals: 18,
            },
            rpcUrls: 'https://rpc.v3s.finance/',
            blockExplorerUrls: [`https://cronoscan.com/`],
          },
        ],
      });
      return true;
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error);
      return false;
    }
  } else {
    console.error("Can't setup the Cronos network on metamask because window.ethereum is undefined");
    return false;
  }
};
