import tokens from './tokens'
import { FarmConfig } from './types'

const vaults: FarmConfig[] = [
  {
    id: 11,
    pid: 0,
    lpSymbol: 'VVS-WCRO',
    lpAddresses: {
      25: '0xbf62c67eA509E86F07c8c69d0286C0636C50270b',
    },
    token: tokens.vvs,
    quoteToken: tokens.cro,
    masterChefAddress: '0x37E78ab58012a662Ff612D97849739320d11AaB8',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '10X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/CRO/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03`,
    removeLiquidityUrl: `https://vvs.finance/remove/CRO/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03`,
  },
  {
    id: 12,
    pid: 1,
    lpSymbol: 'VVS-USDC',
    lpAddresses: {
      25: '0x814920D1b8007207db6cB5a2dD92bF0b082BDBa1',
    },
    token: tokens.vvs,
    quoteToken: tokens.usdc,
    masterChefAddress: '0x4875AE5e5D9e619100cFd8c411A5122E5669871a',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '1X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03`,
    removeLiquidityUrl: `https://vvs.finance/remove/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03`,
  },
  {
    id: 13,
    pid: 2,
    lpSymbol: 'VVS-USDT',
    lpAddresses: {
      25: '0x280aCAD550B2d3Ba63C8cbff51b503Ea41a1c61B',
    },
    token: tokens.vvs,
    quoteToken: tokens.usdt,
    masterChefAddress: '0xc0c81a7Bcf5825503c741C485Da7F4422bdbDE62',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '0.1X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/0x66e428c3f67a68878562e79A0234c1F83c208770/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03`,
    removeLiquidityUrl: `https://vvs.finance/remove/0x66e428c3f67a68878562e79A0234c1F83c208770/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03`,
  },
  {
    id: 14,
    pid: 3,
    lpSymbol: 'CRO-ETH',
    lpAddresses: {
      25: '0xA111C17f8B8303280d3EB01BBcd61000AA7F39F9',
    },
    token: tokens.cro,
    quoteToken: tokens.weth,
    masterChefAddress: '0x051116C03C563AF4c1706689fae55875B784beFc',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '0.1X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/CRO/0xe44Fd7fCb2b1581822D0c862B68222998a0c299a`,
    removeLiquidityUrl: `https://vvs.finance/remove/CRO/0xe44Fd7fCb2b1581822D0c862B68222998a0c299a`,
  },
  {
    id: 15,
    pid: 4,
    lpSymbol: 'CRO-WBTC',
    lpAddresses: {
      25: '0x8F09fFf247B8fDB80461E5Cf5E82dD1aE2EBd6d7',
    },
    token: tokens.cro,
    quoteToken: tokens.wbtc,
    masterChefAddress: '0xE95907b6B9FA5dd88F1E255f059A32433B39cA21',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '0.1X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/CRO/0x062E66477Faf219F25D27dCED647BF57C3107d52`,
    removeLiquidityUrl: `https://vvs.finance/remove/CRO/0x062E66477Faf219F25D27dCED647BF57C3107d52`,
  },

  {
    id: 16,
    pid: 5,
    lpSymbol: 'CRO-USDC',
    lpAddresses: {
      25: '0xe61Db569E231B3f5530168Aa2C9D50246525b6d6',
    },
    token: tokens.cro,
    quoteToken: tokens.usdc,
    masterChefAddress: '0xD66b3d425edE81372D4a4F4A3f4A1f615a24FD7d',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '0.1X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/CRO/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59`,
    removeLiquidityUrl: `https://vvs.finance/remove/CRO/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59`,
  },
  {
    id: 17,
    pid: 6,
    lpSymbol: 'USDC-USDT',
    lpAddresses: {
      25: '0x39cC0E14795A8e6e9D02A21091b81FE0d61D82f9',
    },
    token: tokens.usdc,
    quoteToken: tokens.usdt,
    masterChefAddress: '0xdb53a61e50fe6735E6bf382F34d15f35F2F45bD0',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '0.1X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59/0x66e428c3f67a68878562e79A0234c1F83c208770`,
    removeLiquidityUrl: `https://vvs.finance/remove/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59/0x66e428c3f67a68878562e79A0234c1F83c208770`,
  },
  {
    id: 18,
    pid: 7,
    lpSymbol: 'CRO-SHIB',
    lpAddresses: {
      25: '0xc9eA98736dbC94FAA91AbF9F4aD1eb41e7fb40f4',
    },
    token: tokens.cro,
    quoteToken: tokens.shib,
    masterChefAddress: '0xcf226cf5dEdD491813B8Bb1Db9d4080dab9C6655',
    platform: 'VVS',
    startReward: 1637424000,
    multiplier: '0.1X',
    dual: {
      earnLabel: 'VVS'
    },
    tag: "1h lockup",
    addLiquidityUrl: `https://vvs.finance/add/CRO/0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee`,
    removeLiquidityUrl: `https://vvs.finance/remove/CRO/0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee`,
  }
]

export default vaults
