import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    id: 0,
    sousId: 0,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    tokenPerSecond: 16.24,
    lockup: '1h lockup',
    sortOrder: 0,
    isFinished: true,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: true,
  },
  {
    id: 1,
    sousId: 1,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    tokenPerSecond: 16.24,
    sortOrder: 0,
    lockup: '7d lockup',
    isFinished: true,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: true,
  },
  {
    id: 2,
    sousId: 2,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    tokenPerSecond: 16.24,
    lockup: '30d lockup',
    sortOrder: 0,
    isFinished: true,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: true,
  },
  {
    id: 3,
    sousId: 3,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0xe5C67Ba380FB2F70A47b489e94BCeD486bb8fB74',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    lockup: '180d lockup',
    tokenPerSecond: 16.24,
    sortOrder: 0,
    isFinished: true,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: true,
  },

  {
    id: 20,
    sousId: 0,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0x53cE820Ed109D67746a86b55713E30252275c127',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    tokenPerSecond: 16.24,
    lockup: '1h lockup',
    sortOrder: 0,
    isFinished: false,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: false,
  },
  {
    id: 21,
    sousId: 1,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0x53cE820Ed109D67746a86b55713E30252275c127',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    tokenPerSecond: 16.24,
    sortOrder: 0,
    lockup: '7d lockup',
    isFinished: false,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: false,
  },
  {
    id: 22,
    sousId: 2,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0x53cE820Ed109D67746a86b55713E30252275c127',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    tokenPerSecond: 16.24,
    lockup: '30d lockup',
    sortOrder: 0,
    isFinished: false,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: false,
  },
  {
    id: 23,
    sousId: 3,
    stakingToken: tokens.krx,
    earningToken: tokens.krx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      25: '0x53cE820Ed109D67746a86b55713E30252275c127',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    lockup: '180d lockup',
    tokenPerSecond: 16.24,
    sortOrder: 0,
    isFinished: false,
    isLocked: true,
    isTaxed: false,
    enableEmergencyWithdraw: false,
  },
]

export default pools
