import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import VaultBankABI from 'config/abi/vaultBank.json'
import VaultStrategyABI from 'config/abi/vaultStrategy.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { ethers } from 'ethers'
import contracts from 'config/constants/contracts'

export const fetchVaultUserAllowances = async (account: string, vaultsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const calls = vaultsToFetch.map((vault) => {
    const lpContractAddress = getAddress(vault.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, getAddress(contracts?.vaultBank)] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls) || []
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchVaultUserTokenBalances = async (account: string, vaultsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const liveFarms = vaultsToFetch //.filter(item => item.multiplier !=='0X')
  const calls = liveFarms.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls) || []
  let parsedTokenBalances: any = {};

  rawTokenBalances.forEach((tokenBalance, idx) => {
    parsedTokenBalances[liveFarms[idx].id] = new BigNumber(tokenBalance).toJSON()
  })

  return vaultsToFetch.map(item =>{
    return parsedTokenBalances[item.id] || new BigNumber(0).toJSON()
  })
}

export const fetchVaultUserStakedBalances = async (account: string, vaultsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const calls = vaultsToFetch.map((farm) => {
    return {
      address: getAddress(contracts?.vaultBank),
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(VaultBankABI, calls) || []
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchVaultUserEarnings = async (account: string, vaultsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const liveFarms = vaultsToFetch.filter(item => item.multiplier !== '0X')
  const calls = liveFarms.map((farm) => {
    return {
      address: getAddress(contracts?.vaultBank),
      name: 'pendingReward',
      params: [farm.pid, 0, account],
    }
  })

  const rawEarnings = await multicall(VaultBankABI, calls) || []
  let parsedEarnings: any = {}
  rawEarnings.forEach((earnings, idx) =>{
    parsedEarnings[liveFarms[idx].id] = new BigNumber(earnings).toJSON()
  })

  return vaultsToFetch.map(item =>{
    return parsedEarnings[item.id] || new BigNumber(0).toJSON()
  })
}

export const fetchUnfrozenDepositTime = async (account: string, vaultsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const liveFarms = vaultsToFetch.filter(item => item.multiplier !== '0X')
  const calls = liveFarms.map((farm) => {
    return {
      address: getAddress(contracts?.vaultBank),
      name: 'unfrozenDepositTime',
      params: [farm.pid, account],
    }
  })

  const rawTimes = await multicall(VaultBankABI, calls) || []
  let parsed: any = {}
  rawTimes.forEach((data, idx) =>{
    parsed[liveFarms[idx].id] = parseInt(data)
  })

  return vaultsToFetch.map(item =>{
    return parsed[item.id] || 0
  })
}

const farmABI: any[] = [...erc20ABI, ...VaultBankABI, ...VaultStrategyABI]

/**
 * merge all farm queries in one
 * */
export const fetchAllVaultUserData = async (account: string, vaultsToFetch: FarmConfig[]): Promise<any[]> => {
  if (!Array.isArray(vaultsToFetch) || !vaultsToFetch.length) return []

  const liveFarms = vaultsToFetch //.filter(item => item.multiplier !== '0X')

  const calls = []
  liveFarms.forEach(farm => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    calls.push({ address: lpContractAddress, name: 'allowance', params: [account, getAddress(contracts?.vaultBank)] })
    calls.push({ address: lpContractAddress, name: 'balanceOf', params: [account] })
    calls.push({ address: getAddress(contracts?.vaultBank), name: 'userInfo', params: [farm.pid, account] })
    calls.push({ address: farm.masterChefAddress, name: 'pendingHarvest', params: [] })
    calls.push({ address: farm.masterChefAddress, name: 'lastEarnTime', params: [] })
    calls.push({ address: farm.masterChefAddress, name: 'pendingHarvestDollarValue', params: [] })

    calls.push({ address: getAddress(contracts?.vaultBank), name: 'unfrozenStakeTime', params: [farm.pid, account] })
    calls.push({ address: getAddress(contracts?.vaultBank), name: 'stakedWantTokens', params: [farm.pid, account] })
    calls.push({ address: getAddress(contracts?.vaultBank), name: 'pendingReward', params: [farm.pid, 0, account] })
  })

  try {
    const results = await multicall(farmABI, calls) || []
    return liveFarms.map((farm, index) => {
      const i = index * 9
      const allowance = new BigNumber(results[i]).toJSON()
      const tokenBalance = new BigNumber(results[i + 1]).toJSON()
      const stakedAmount = results[i + 7].toString()
      const stakedBalance = new BigNumber(stakedAmount).toJSON()

      const earnings = new BigNumber(results[i + 8]).toJSON()
      const pendingHarvest = new BigNumber(results[i + 3]).toJSON()
      const lastEarnTime = new BigNumber(results[i + 4]).toJSON()
      const pendingHarvestDollarValue = new BigNumber(results[i + 5]).toJSON()

      const unfrozenDepositTime = new BigNumber(results[i + 6]).toJSON()

      return {
        id: farm.id,
        pid: farm.pid,
        allowance,
        tokenBalance,
        stakedBalance,
        earnings,
        pendingHarvest,
        unfrozenDepositTime,
        lastEarnTime,
        pendingHarvestDollarValue
      }
    })
  } catch (error) {
    console.error('fetchAllFarmUserData', error)
  }
  return []
}
