import { createAction } from '@reduxjs/toolkit'
import { PoolVersion } from '../../utils/poolVersion'
import { BigNumber } from 'bignumber.js'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export interface SwapRoutePool {
  id: string
  version: PoolVersion
  swapAmount?: string
  swapPercentage?: number
}

export interface SwapRoute {
  pools: SwapRoutePool[]
  path: any[]
  version?: PoolVersion
}

export interface ChartSwap {
  isOthers: boolean
  firstPoolAddress?: string
  secondPoolAddress?: string
  percentage: number
  noPools?: number
}

export interface ChartData {
  validSwap: boolean
  swaps: ChartSwap[]
  inputPriceValue: BigNumber
  outputPriceValue: BigNumber
  noPools?: number
  routes: SwapRoute[]
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('swap/selectCurrency')
export const switchCurrencies = createAction<void>('swap/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('swap/typeInput')
export const replaceSwapState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('swap/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('swap/setRecipient')
