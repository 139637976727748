import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { ethers } from 'ethers'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, farm?.masterChefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls) || []
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const liveFarms = farmsToFetch.filter(item => item.multiplier !=='0X')
  const calls = liveFarms.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls) || []
  let parsedTokenBalances: any = {};

  rawTokenBalances.forEach((tokenBalance, idx) => {
    parsedTokenBalances[liveFarms[idx].id] = new BigNumber(tokenBalance).toJSON()
  })

  return farmsToFetch.map(item =>{
    return parsedTokenBalances[item.id] || new BigNumber(0).toJSON()
  })
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const calls = farmsToFetch.map((farm) => {
    return {
      address: farm.masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls) || []
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const liveFarms = farmsToFetch.filter(item => item.multiplier !== '0X')
  const calls = liveFarms.map((farm) => {
    return {
      address: farm.masterChefAddress,
      name: 'pendingReward',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls) || []
  let parsedEarnings: any = {}
  rawEarnings.forEach((earnings, idx) =>{
    parsedEarnings[liveFarms[idx].id] = new BigNumber(earnings).toJSON()
  })

  return farmsToFetch.map(item =>{
    return parsedEarnings[item.id] || new BigNumber(0).toJSON()
  })
}

export const fetchUnfrozenDepositTime = async (account: string, farmsToFetch: FarmConfig[], signer?: ethers.Signer | ethers.providers.Provider) => {
  const liveFarms = farmsToFetch.filter(item => item.multiplier !== '0X')
  const calls = liveFarms.map((farm) => {
    return {
      address: farm.masterChefAddress,
      name: 'unfrozenDepositTime',
      params: [farm.pid, account],
    }
  })

  const rawTimes = await multicall(masterchefABI, calls) || []
  let parsed: any = {}
  rawTimes.forEach((data, idx) =>{
    parsed[liveFarms[idx].id] = parseInt(data)
  })

  return farmsToFetch.map(item =>{
    return parsed[item.id] || 0
  })
}

const farmABI: any[] = [...erc20ABI, ...masterchefABI]

/**
 * merge all farm queries in one
 * */
export const fetchAllFarmUserData = async (account: string, farmsToFetch: FarmConfig[]): Promise<any[]> => {
  if (!Array.isArray(farmsToFetch) || !farmsToFetch.length) return []

  const liveFarms = farmsToFetch.filter(item => item.multiplier !== '0X')

  const calls = []
  liveFarms.forEach(farm => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    calls.push({ address: lpContractAddress, name: 'allowance', params: [account, farm?.masterChefAddress] })
    calls.push({ address: lpContractAddress, name: 'balanceOf', params: [account] })
    calls.push({ address: farm.masterChefAddress, name: 'userInfo', params: [farm.pid, account] })
    calls.push({ address: farm.masterChefAddress, name: 'pendingReward', params: [farm.pid, account] })
    calls.push({ address: farm.masterChefAddress, name: 'unfrozenDepositTime', params: [farm.pid, account] })
  })

  try {
    const results = await multicall(farmABI, calls) || []

    return liveFarms.map((farm, index) => {
      const i = index * 5
      const allowance = new BigNumber(results[i]).toJSON()
      const tokenBalance = new BigNumber(results[i + 1]).toJSON()
      const stakedAmount = results[i + 2]?.[0]?.toString()
      const stakedBalance = new BigNumber(stakedAmount).toJSON()
      const earnings = new BigNumber(results[i + 3]).toJSON()
      const unfrozenDepositTime = new BigNumber(results[i + 4]).toJSON()

      return {
        id: farm.id,
        pid: farm.pid,
        allowance,
        tokenBalance,
        stakedBalance,
        earnings,
        unfrozenDepositTime
      }
    })
  } catch (error) {
    console.error('fetchAllFarmUserData', error)
  }
  return []
}
