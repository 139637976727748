import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'
import { ROUTER_ADDRESS } from '../config/constants'

export const getAddress = (address: Address): string => {
  const mainNetChainId = 25
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}
export const getTokenLockerAddress = () => {
  return getAddress(addresses.tokenLocker)
}

// getKRXAddress
export const getKRXAddress = () => {
  return getAddress(tokens.krx.address)
}
export const getwETHAddress = () => {
  return getAddress(tokens.weth.address)
}

export const getUSDCAddress = () => {
  return getAddress(tokens.usdc.address)
}

export const getTokenAddress = () => {
  return getAddress(tokens.usdc.address)
}

export const getfPolyAddress = () => {
  return getAddress(tokens.fplx.address)
}
/*
//No default chef address from now
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
*/

export const getStakingChefAddress = () => {
  return getAddress(addresses.stakingChefAddress)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWmaticAddress = () => {
  return getAddress(tokens.wmatic.address)
}
export const getLotteryAddress = () => {
  return getAddress(addresses.lottery)
}

export const getLotteryTicketAddress = () => {
  return getAddress(addresses.lotteryNFT)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}

export const getLpCompensateAddress = () => {
  return getAddress(addresses.LPCompensation)
}

export const getBuyNFTAddress = () => {
  return getAddress(addresses.buyNFT)
}
export const airDropAddress = () => {
  return getAddress(addresses.airDrop)
}
export const getRouterAddress = () => {
  return getAddress(ROUTER_ADDRESS)
}
