import { useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { orderBy } from 'lodash';
import { Team, Token } from 'config/constants/types';
import tokens from 'config/constants/tokens';
import Nfts from 'config/constants/nfts';
import { getBalanceNumber } from 'utils/formatBalance';
import { BIG_ZERO } from 'utils/bigNumber';
import useRefresh from 'hooks/useRefresh';
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers';
import { getKRXAddress, getWmaticAddress, getwETHAddress, getAddress } from 'utils/addressHelpers';

import { simpleRpcProvider } from 'utils/providers';
import {
  fetchFarmsPublicDataAsync,
  fetchVaultsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  setBlock,
} from './actions';
import {
  State,
  Farm,
  Pool,
  ProfileState,
  TeamsState,
  AchievementState,
  PriceState,
  FarmsState,
  VaultsState,
} from './types';
import { fetchProfile } from './profile';
import { fetchTeam, fetchTeams } from './teams';
import { fetchAchievements } from './achievements';
import { fetchPrices } from './prices';
import { fetchWalletNfts } from './collectibles';
import { getCanClaim } from './predictions/helpers';
import { transformPool } from './pools/helpers';
import { fetchPoolsStakingLimitsAsync } from './pools';

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch();
  const { slowRefresh } = useRefresh();
  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await simpleRpcProvider?.getBlockNumber();
      dispatch(fetchPoolsPublicDataAsync(blockNumber));
    };
    // dispatch(fetchFarmsPublicDataAsync())
    // dispatch(fetchVaultsPublicDataAsync())
    // fetchPoolsPublicData()
    dispatch(fetchPoolsStakingLimitsAsync());
  }, [dispatch, slowRefresh, simpleRpcProvider]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const blockNumber = await simpleRpcProvider?.getBlockNumber();
      dispatch(setBlock(blockNumber));
    }, 6000);

    return () => clearInterval(interval);
  }, [dispatch, simpleRpcProvider]);
};

// Farms

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms);
  return farms;
};

export const useVaults = (): VaultsState => {
  const vaults = useSelector((state: State) => state.vaults);
  return vaults;
};

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid));
  return farm;
};

export const useFarmFromId = (id): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.id === id));
  return farm;
};

export const useVaultFromId = (id): Farm => {
  const farm = useSelector((state: State) => state.vaults.data.find((f) => f.id === id));
  return farm;
};

export const useFarmFromLpSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol));
  return farm;
};

export const useFarmUser = (id) => {
  const farm = useFarmFromId(id);
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
    unfrozenDepositTime: farm.userData ? farm.userData.unfrozenDepositTime : 0,
  };
};

export const useVaultUser = (id) => {
  const farm = useVaultFromId(id);

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
    unfrozenDepositTime: farm.userData ? farm.userData.unfrozenDepositTime : 0,
  };
};

// Return a farm for a given token symbol. The farm is filtered based on attempting to return a farm with a quote token from an array of preferred quote tokens
export const useFarmFromTokenSymbol = (tokenSymbol: string, preferredQuoteTokens?: string[]): Farm => {
  const farms = useSelector((state: State) => state.farms.data.filter((farm) => farm.token.symbol === tokenSymbol));
  const filteredFarm = filterFarmsByQuoteToken(farms, preferredQuoteTokens);
  return filteredFarm;
};

export const useBusdPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid);
  const maticPriceBusd = usePriceBnbBusd();
  const ethPriceBusd = usePricewETHBusd();
  const quoteTokenFarm = useFarmFromTokenSymbol(farm?.quoteToken?.symbol);

  // Catch in case a farm isn't found
  if (!farm) {
    return null;
  }

  // With a quoteToken of BUSD or wBNB, it is straightforward to return the token price.
  if (farm.quoteToken.symbol === 'BUSD') {
    return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO;
  }

  if (farm.quoteToken.symbol === 'wMATIC' || farm.quoteToken.symbol === 'WMATIC') {
    return maticPriceBusd.gt(0) && maticPriceBusd.times(farm.tokenPriceVsQuote);
  }

  if (farm.quoteToken.symbol === 'WETH' || farm.quoteToken.symbol === 'wETH') {
    return ethPriceBusd.gt(0) && ethPriceBusd.times(farm.tokenPriceVsQuote);
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or wBNB, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC
  // we find the pBTC farm (pBTC - BNB)'s quote token - BNB
  // from the BNB - pBTC BUSD price, we can calculate the PNT - BUSD price

  if (quoteTokenFarm.quoteToken.symbol === 'wMATIC' || farm.quoteToken.symbol === 'WMATIC') {
    const quoteTokenInBusd = maticPriceBusd.gt(0) && maticPriceBusd.times(quoteTokenFarm.tokenPriceVsQuote);
    return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd) : BIG_ZERO;
  }

  if (quoteTokenFarm.quoteToken.symbol === 'BUSD') {
    const quoteTokenInBusd = quoteTokenFarm.tokenPriceVsQuote;
    return quoteTokenInBusd ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInBusd) : BIG_ZERO;
  }

  // Catch in case token does not have immediate or once-removed BUSD/wBNB quoteToken
  return BIG_ZERO;
};

export const useBusdPriceFromToken = (tokenSymbol: string) => {
  const tokenFarmForPriceCalc = useFarmFromTokenSymbol(tokenSymbol);
  const tokenPrice = useBusdPriceFromPid(tokenFarmForPriceCalc?.pid);
  return tokenPrice;
};

export const usePriceFromToken = (token: Token): BigNumber => {
  if (!token?.address) return BIG_ZERO;

  const address = getAddress(token.address);
  if (!address) return BIG_ZERO;
  const price = useGetApiPrice(address);
  if (price) return new BigNumber(price);
  return BIG_ZERO;
};

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol);
  const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid);
  const lpTokenPrice = new BigNumber(getBalanceNumber(farm.lpTotalSupply))
    .div(farm.lpTotalInQuoteToken)
    .times(farmTokenPriceInUsd)
    .times(2);
  return farm.lpTotalSupply && farm.lpTotalInQuoteToken ? lpTokenPrice : BIG_ZERO;
};

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account));
    }
  }, [account, dispatch, fastRefresh]);

  const pools = useSelector((state: State) => state.pools.data);

  return pools.map(transformPool);
};

export const usePoolFromId = (id: number): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.id === id));
  return transformPool(pool);
};

export const useCakeVault = () => {
  const {
    totalShares: totalSharesAsString,
    pricePerFullShare: pricePerFullShareAsString,
    totalCakeInVault: totalCakeInVaultAsString,
    estimatedCakeBountyReward: estimatedCakeBountyRewardAsString,
    totalPendingCakeHarvest: totalPendingCakeHarvestAsString,
    fees: { performanceFee, callFee, withdrawalFee, withdrawalFeePeriod },
    userData: {
      isLoading,
      userShares: userSharesAsString,
      cakeAtLastUserAction: cakeAtLastUserActionAsString,
      lastDepositedTime,
      lastUserActionTime,
    },
  } = useSelector((state: State) => state.pools.cakeVault);

  const estimatedCakeBountyReward = useMemo(() => {
    return new BigNumber(estimatedCakeBountyRewardAsString);
  }, [estimatedCakeBountyRewardAsString]);

  const totalPendingCakeHarvest = useMemo(() => {
    return new BigNumber(totalPendingCakeHarvestAsString);
  }, [totalPendingCakeHarvestAsString]);

  const totalShares = useMemo(() => {
    return new BigNumber(totalSharesAsString);
  }, [totalSharesAsString]);

  const pricePerFullShare = useMemo(() => {
    return new BigNumber(pricePerFullShareAsString);
  }, [pricePerFullShareAsString]);

  const totalCakeInVault = useMemo(() => {
    return new BigNumber(totalCakeInVaultAsString);
  }, [totalCakeInVaultAsString]);

  const userShares = useMemo(() => {
    return new BigNumber(userSharesAsString);
  }, [userSharesAsString]);

  const cakeAtLastUserAction = useMemo(() => {
    return new BigNumber(cakeAtLastUserActionAsString);
  }, [cakeAtLastUserActionAsString]);

  return {
    totalShares,
    pricePerFullShare,
    totalCakeInVault,
    estimatedCakeBountyReward,
    totalPendingCakeHarvest,
    fees: {
      performanceFee,
      callFee,
      withdrawalFee,
      withdrawalFeePeriod,
    },
    userData: {
      isLoading,
      userShares,
      cakeAtLastUserAction,
      lastDepositedTime,
      lastUserActionTime,
    },
  };
};

// Profile

export const useFetchProfile = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!account) return;

    dispatch(fetchProfile(account));
  }, [account, dispatch]);
};

export const useProfile = () => {
  const { isInitialized, isLoading, data, hasRegistered }: ProfileState = useSelector((state: State) => state.profile);
  return { profile: data, hasProfile: isInitialized && hasRegistered, isInitialized, isLoading };
};

// Teams

export const useTeam = (id: number) => {
  const team: Team = useSelector((state: State) => state.teams.data[id]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTeam(id));
  }, [id, dispatch]);

  return team;
};

export const useTeams = () => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector((state: State) => state.teams);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  return { teams: data, isInitialized, isLoading };
};

// Achievements

export const useFetchAchievements = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account) {
      dispatch(fetchAchievements(account));
    }
  }, [account, dispatch]);
};

export const useAchievements = () => {
  const achievements: AchievementState['data'] = useSelector((state: State) => state.achievements.data);
  return achievements;
};

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(fetchPrices());
  }, [dispatch, slowRefresh]);
};

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data);
  return prices;
};

export const useGetApiPrice = (address: string) => {
  const prices = useGetApiPrices();

  if (!prices || !address) {
    return null;
  }

  return prices[address.toLowerCase()];
};

export const usePriceBnbBusd = (): BigNumber => {
  const address = getWmaticAddress();
  const price = useGetApiPrice(address);
  if (price) return new BigNumber(price);
  return BIG_ZERO;
};

export const usePricewETHBusd = (): BigNumber => {
  const address = getwETHAddress();
  const price = useGetApiPrice(address);
  if (price) return new BigNumber(price);
  return BIG_ZERO;
};

export const usePriceCakeBusd = (): BigNumber => {
  const clxAddress = getKRXAddress();
  const price = useGetApiPrice(clxAddress);
  if (price) return new BigNumber(price);
  return BIG_ZERO;
};

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block);
};

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock);
};

// Predictions
export const useIsHistoryPaneOpen = () => {
  return useSelector((state: State) => state.predictions.isHistoryPaneOpen);
};

export const useIsChartPaneOpen = () => {
  return useSelector((state: State) => state.predictions.isChartPaneOpen);
};

export const useGetRounds = () => {
  return useSelector((state: State) => state.predictions.rounds);
};

export const useGetSortedRounds = () => {
  const roundData = useGetRounds();
  return orderBy(Object.values(roundData), ['epoch'], ['asc']);
};

export const useGetCurrentEpoch = () => {
  return useSelector((state: State) => state.predictions.currentEpoch);
};

export const useGetIntervalBlocks = () => {
  return useSelector((state: State) => state.predictions.intervalBlocks);
};

export const useGetBufferBlocks = () => {
  return useSelector((state: State) => state.predictions.bufferBlocks);
};

export const useGetTotalIntervalBlocks = () => {
  const intervalBlocks = useGetIntervalBlocks();
  const bufferBlocks = useGetBufferBlocks();
  return intervalBlocks + bufferBlocks;
};

export const useGetRound = (id: string) => {
  const rounds = useGetRounds();
  return rounds[id];
};

export const useGetCurrentRound = () => {
  const currentEpoch = useGetCurrentEpoch();
  const rounds = useGetSortedRounds();
  return rounds.find((round) => round.epoch === currentEpoch);
};

export const useGetPredictionsStatus = () => {
  return useSelector((state: State) => state.predictions.status);
};

export const useGetHistoryFilter = () => {
  return useSelector((state: State) => state.predictions.historyFilter);
};

export const useGetCurrentRoundBlockNumber = () => {
  return useSelector((state: State) => state.predictions.currentRoundStartBlockNumber);
};

export const useGetMinBetAmount = () => {
  const minBetAmount = useSelector((state: State) => state.predictions.minBetAmount);
  return useMemo(() => new BigNumber(minBetAmount), [minBetAmount]);
};

export const useGetIsFetchingHistory = () => {
  return useSelector((state: State) => state.predictions.isFetchingHistory);
};

export const useGetHistory = () => {
  return useSelector((state: State) => state.predictions.history);
};

export const useGetHistoryByAccount = (account: string) => {
  const bets = useGetHistory();
  return bets ? bets[account] : [];
};

export const useGetBetByRoundId = (account: string, roundId: string) => {
  const bets = useSelector((state: State) => state.predictions.bets);

  if (!bets[account]) {
    return null;
  }

  if (!bets[account][roundId]) {
    return null;
  }

  return bets[account][roundId];
};

export const useBetCanClaim = (account: string, roundId: string) => {
  const bet = useGetBetByRoundId(account, roundId);

  if (!bet) {
    return false;
  }

  return getCanClaim(bet);
};

export const useGetLastOraclePrice = (): BigNumber => {
  const lastOraclePrice = useSelector((state: State) => state.predictions.lastOraclePrice);
  return new BigNumber(lastOraclePrice);
};

// Collectibles
export const useGetCollectibles = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  const { isInitialized, isLoading, data } = useSelector((state: State) => state.collectibles);
  const identifiers = Object.keys(data);

  useEffect(() => {
    // Fetch nfts only if we have not done so already
    if (!isInitialized) {
      dispatch(fetchWalletNfts(account));
    }
  }, [isInitialized, account, dispatch]);

  return {
    isInitialized,
    isLoading,
    tokenIds: data,
    nftsInWallet: Nfts.filter((nft) => identifiers.includes(nft.identifier)),
  };
};
