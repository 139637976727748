import { ethers } from 'ethers'
import SorProvider, { PoolVersion } from './app'
import HardcodeVVSPairs from './hardcode-vvs-pairs.json'
import HardcodeCronaSwapPairs from './hardcode-crona-pairs.json'
import HardcodeMMFPairs from './hardcode-mmf-pairs.json'
import HardcodePhotonSwapPairs from './hardcode-photon-pairs.json'
import HardcodePolydexPairs from './hardcode-polydex-pairs.json'
import HardcodeCrodexPairs from './hardcode-crodex-pairs.json'

function getSubgraphUrl() {
  return SorProvider.getSubgraphUrl()
}

// LEGACY FUNCTION - Keep Input/Output Format
export async function getPoolsWithTokens(tokenIn: string, tokenOut: string) {
  // GraphQL is case-sensitive
  // Always use checksum addresses
  tokenIn = ethers.utils.getAddress(tokenIn)
  tokenOut = ethers.utils.getAddress(tokenOut)

  const query = `
      query ($tokens: [Bytes!]) {
          pools (first: 1000, where: {tokensList_contains: $tokens, active: true}) {
            id
            swapFee
            totalWeight
            tokensList
            tokens {
              id
              address
              balance
              decimals
              symbol
              denormWeight
            }
          }
        }
    `

  const variables = {
    tokens: [tokenIn, tokenOut]
  }
  const response = await fetch(getSubgraphUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    })
  })
  console.log('getPoolsWithTokens', tokenIn, tokenOut, getSubgraphUrl(), response)
  const { data } = await response.json()
  return data
}

// LEGACY FUNCTION - Keep Input/Output Format
export async function getTokenPairs(token: string) {
  // GraphQL is case-sensitive
  // Always use checksum addresses
  token = ethers.utils.getAddress(token)

  const query = `
      query ($token: [Bytes!]) {
          pools (first: 1000, where: {tokensList_contains: $token, active: true}) {
            tokensList
          }
        }
    `

  const variables = {
    token: [token]
  }

  const response = await fetch(getSubgraphUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    })
  })

  const { data } = await response.json()
  return data
}

export async function getAllPublicQuickSwapPairs(): Promise<{ pools: any }> {
  const url = 'https://gnode.photonswap.finance/subgraphs/name/dexbruce/photonswap';
  console.log('url', url)
  if (!url) {
    return { pools: [] }
  }

  const query = `
      {
          pairs(first: 10, orderBy: reserveETH, orderDirection: desc) {
            id
            token0 {
              address: id
              balance: totalLiquidity
              decimals
              symbol
            }
            token1 {
              address: id
              balance: totalLiquidity
              decimals
              symbol
            }
            totalShares: totalSupply
          }
      }
    `

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query
      })
    })
    console.log('response', response)
    const { data } = await response.json()
    console.log('data', data)
    let pools = []
    if (data) {
      const { pairs } = data
      if (Array.isArray(pairs) && pairs.length) {
        pools = pairs.map(p => {
          const { token0 = {}, token1 = {}, ...rest } = p
          const tokensList = [token0.address, token1.address]
          const tokens = [
            Object.assign({}, token0, {
              id: tokensList.join('-'),
              denormWeight: 50
            }),
            Object.assign({}, token1, {
              id: tokensList.join('-'),
              denormWeight: 50
            })
          ]
          return Object.assign({}, rest, {
            swapFee: 0.0025, // 0.3%
            totalWeight: 100,
            tokens,
            tokensList,
            version: PoolVersion.CronaSwap
          })
        })
      }
    }
    return { pools }
  } catch (e) {
    return { pools: [] }
  }
}


export async function getAllPublicMMFPairs(): Promise<{ pools: any }> {
    const url = 'https://graph.mm.finance/subgraphs/name/madmeerkat-finance/exchange';
    console.log('url', url)
    if (!url) {
      return { pools: [] }
    }

    const query = `
        {
            pairs(first: 200, orderBy: reserveCRO, orderDirection: desc,where:{reserveUSD_gt:5000}) {
              id
              token0 {
                address: id
                balance: totalLiquidity
                decimals
                symbol
              }
              token1 {
                address: id
                balance: totalLiquidity
                decimals
                symbol
              }
              totalShares: totalSupply
            }
        }
      `

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query
        })
      })
      console.log('response', response)
      const { data } = await response.json()
      console.log('data', data)
      let pools = []
      if (data) {
        const { pairs } = data
        if (Array.isArray(pairs) && pairs.length) {
          pools = pairs.map(p => {
            const { token0 = {}, token1 = {}, ...rest } = p
            const tokensList = [token0.address, token1.address]
            const tokens = [
              Object.assign({}, token0, {
                id: tokensList.join('-'),
                denormWeight: 50
              }),
              Object.assign({}, token1, {
                id: tokensList.join('-'),
                denormWeight: 50
              })
            ]
            return Object.assign({}, rest, {
              swapFee: 0.0025, // 0.3%
              totalWeight: 100,
              tokens,
              tokensList,
              version: PoolVersion.MMF
            })
          })
        }
      }
      return { pools }
    } catch (e) {
      return { pools: [] }
    }
  }
export async function getHardCodePolydexPairs(): Promise<{ pools: any }> {
  let pools = HardcodePolydexPairs;
  return { pools }
}
export async function getHardcodeCrodexPairs(): Promise<{ pools: any }> {
  let pools = HardcodeCrodexPairs;
  return { pools }
}


export async function getHardCodeVVSPairs(): Promise<{ pools: any }> {
  let pools = HardcodeVVSPairs;
  return { pools }
}
export async function getAllPublicVVSPairs(): Promise<{ pools: any }> {
  const url = 'https://graph.vvs.finance/exchange';
  console.log('url', url)
  if (!url) {
    return { pools: [] }
  }

  const query = `
      {
          pairs(first: 100, orderBy: reserveCRO, orderDirection: desc,where:{reserveUSD_gt:500}) {
            id
            token0 {
              address: id
              balance: totalLiquidity
              decimals
              symbol
            }
            token1 {
              address: id
              balance: totalLiquidity
              decimals
              symbol
            }
            totalShares: totalSupply
          }
      }
    `

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query
      })
    })
    console.log('response', response)
    const { data } = await response.json()
    console.log('data', data)
    let pools = []
    if (data) {
      const { pairs } = data
      if (Array.isArray(pairs) && pairs.length) {
        pools = pairs.map(p => {
          const { token0 = {}, token1 = {}, ...rest } = p
          const tokensList = [token0.address, token1.address]
          const tokens = [
            Object.assign({}, token0, {
              id: tokensList.join('-'),
              denormWeight: 50
            }),
            Object.assign({}, token1, {
              id: tokensList.join('-'),
              denormWeight: 50
            })
          ]
          return Object.assign({}, rest, {
            swapFee: 0.003, // 0.3%
            totalWeight: 100,
            tokens,
            tokensList,
            version: PoolVersion.VVS
          })
        })
      }
    }
    console.log(JSON.stringify(pools))

    return { pools }
  } catch (e) {
    return { pools: [] }
  }
}
export async function getHardcodeMMFPairs(): Promise<{ pools: any }> {
  let pools = HardcodeMMFPairs;
  return { pools }
}

export async function getHardCodeCronaSwapPairs(): Promise<{ pools: any }> {
  let pools = HardcodeCronaSwapPairs;
  return { pools }
}
export async function getAllPublicCronaSwapPairs(): Promise<{ pools: any }> {
  const url = 'https://graph.cronaswap.org/subgraphs/name/cronaswap/exchange';
  console.log('url', url)
  if (!url) {
    return { pools: [] }
  }

  const query = `
      {
          pairs(first: 100, orderBy: reserveUSD, orderDirection: desc,where:{reserveUSD_gt:5000}) {
            id
            token0 {
              address: id
              balance: totalLiquidity
              decimals
              symbol
            }
            token1 {
              address: id
              balance: totalLiquidity
              decimals
              symbol
            }
            totalShares: totalSupply
          }
      }
    `

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query
      })
    })
    console.log('response', response)
    const { data } = await response.json()
    console.log('data', data)
    let pools = []
    if (data) {
      const { pairs } = data
      if (Array.isArray(pairs) && pairs.length) {
        pools = pairs.map(p => {
          const { token0 = {}, token1 = {}, ...rest } = p
          const tokensList = [token0.address, token1.address]
          const tokens = [
            Object.assign({}, token0, {
              id: tokensList.join('-'),
              denormWeight: 50
            }),
            Object.assign({}, token1, {
              id: tokensList.join('-'),
              denormWeight: 50
            })
          ]
          return Object.assign({}, rest, {
            swapFee: 0.0025, // 0.3%
            totalWeight: 100,
            tokens,
            tokensList,
            version: PoolVersion.CronaSwap
          })
        })
      }
    }
    return { pools }
  } catch (e) {
    return { pools: [] }
  }
}
export async function getHardCodePhotonSwapPairs(): Promise<{ pools: any }> {
  let pools = HardcodePhotonSwapPairs;
  return { pools }
}

// Returns all public pools
export async function getAllPublicPolydexPairs() {
  // const minLiquidity = SorProvider.isMainnet() ? 10 : -1
  const minLiquidity = -1
  const query = `
      {
          pools (first: 1000, where: {liquidity_gt: ${minLiquidity}}, orderBy: liquidity, orderDirection: desc) {
            id
            swapFee
            totalWeight
            tokens (orderBy: "denormWeight", orderDirection: "desc") {
              id
              address
              balance
              decimals
              symbol
              denormWeight
            }
            tokensList
            totalShares
          }
      }
    `

  const response = await fetch(getSubgraphUrl(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query
    })
  })
  // console.log('getAllPublicPolydexPairs', getSubgraphUrl(), response)
  const { data } = await response.json()
  const pools = Array.isArray(data?.pools) ? data.pools : []
  if (pools.length) {
    pools.forEach((p: any) => {
      p.version = 3001
    })
  }
  // console.log('polydex', pools)
  return { pools }
}

// Returns all public pools
export async function getAllPublicSwapPools() {
  let mode = 'hardcoded';
  // const pairs = await getAllPublicQuickSwapPairs();
  const [
    // polydexPairs,
    vvsPairs
     ,cronaSwapPairs,
     crodexPairs,
      mmfPairs
    // , photoSwapPairs,mmfPairs
  ] = await Promise.all([
    // mode ==='hardcoded' ? getHardCodePolydexPairs(): getAllPublicPolydexPairs(),
    getHardCodeVVSPairs(),
    getHardCodeCronaSwapPairs(),
    getHardcodeCrodexPairs(),
    
    // getHardCodePhotonSwapPairs(),
    getHardcodeMMFPairs(),
    // getAllPublicMMFPairs()
  ])
  // console.log('PolydexPairs: ', polydexPairs.pools)
  console.log('vvs', vvsPairs.pools)
  // console.log('CronaSwapPairs', cronaSwapPairs.pools.length)
  // console.log('PhotonSwapPairs', photoSwapPairs.pools.length)
  // console.log('mmfPairs', mmfPairs.pools.length)
  return {
    pools: []
    // .concat(polydexPairs?.pools || [])
      .concat(vvsPairs?.pools || [])
       .concat(cronaSwapPairs?.pools || [])
       .concat(crodexPairs?.pools || [])
       .concat(mmfPairs?.pools || [])
      // .concat(photoSwapPairs?.pools || [])
      // .concat(mmfPairs?.pools || [])
      // .concat(dfynPairs?.pools || [])
      // .concat(fPairs?.pools || [])
  }
}
