import { createReducer } from '@reduxjs/toolkit'
import { setPoolsList, setPoolsOnChain } from './actions'

export interface SwapPoolState {
  poolsList: Array<any>
  onChainPools: Array<any>
}

const initialState: SwapPoolState = {
  poolsList: [],
  onChainPools: []
}

export default createReducer<SwapPoolState>(initialState, builder =>
  builder
    .addCase(setPoolsList, (state, action) => {
      state.poolsList = action.payload.pools
    })
    .addCase(setPoolsOnChain, (state, action) => {
      state.onChainPools = action.payload.pools
    })
)
