import { useMemo } from 'react'
import { Contract } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getBep20Contract,
  getCakeContract,
  getBunnyFactoryContract,
  getBunnySpecialContract,
  getPancakeRabbitContract,
  getProfileContract,
  getIfoV1Contract,
  getIfoV2Contract,
  getLotteryContract,
  getLotteryTicketContract,
  getMasterchefContract,
  getVaultBankContract,
  getVaultStrategyContract,
  getPointCenterIfoContract,
  getSouschefContract,
  getClaimRefundContract,
  getTradingCompetitionContract,
  getEasterNftContract,
  getErc721Contract,
  getPredictionsContract,
  getChainlinkOracleContract,
  getSouschefV2Contract,
  getFPlxContract,
  getKRXContract,
  getStakingChefContract,
  getTokenSaleByUSDCContract,
  getTokenLockerContract,
  getLPCompensateContract,
  getFutureContract,
  getBuyNFTContract,
  getIAOByUSDCContract, getILOContract,
  getExternalILOContract,
  airDropContract,
  getMulticallContract,
} from 'utils/contractHelpers'
import { usePoolFromId } from 'state/hooks'
import { getAddress, getStakingChefAddress } from 'utils/addressHelpers'
import { Contract as EContract } from '@ethersproject/contracts'
import { abi as GOVERNANCE_ABI } from '@uniswap/governance/build/GovernorAlpha.json'
import { abi as UNI_ABI } from '@uniswap/governance/build/Uni.json'
import { abi as STAKING_REWARDS_ABI } from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { abi as MERKLE_DISTRIBUTOR_ABI } from '@uniswap/merkle-distributor/build/MerkleDistributor.json'
import { ChainId, WMATIC } from '@polydex/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { GOVERNANCE_ADDRESS, MERKLE_DISTRIBUTOR_ADDRESS, UNI } from 'config/constants'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../config/constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../config/constants/abis/ens-public-resolver.json'
import ENS_ABI from '../config/constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/constants/abis/erc20'
import ERC20_ABI from '../config/constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../config/constants/abis/migrator'
import UNISOCKS_ABI from '../config/constants/abis/unisocks.json'
import WMATIC_ABI from '../config/constants/abis/weth.json'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIfoV1Contract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIfoV1Contract(address, library.getSigner()), [address, library])
}

export const useIfoV2Contract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIfoV2Contract(address, library.getSigner()), [address, library])
}

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

export const useCake = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCakeContract(library.getSigner()), [library])
}

export const useBunnyFactory = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnyFactoryContract(library.getSigner()), [library])
}

export const usePancakeRabbits = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPancakeRabbitContract(library.getSigner()), [library])
}

export const useProfile = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getProfileContract(library.getSigner()), [library])
}

export const useLottery = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLotteryContract(library.getSigner()), [library])
}

export const useKRX = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getKRXContract(library.getSigner()), [library])
}

export const useLotteryTicket = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLotteryTicketContract(library.getSigner()), [library])
}

export const useMasterchef = (chefAddress?: string) => {
  chefAddress = chefAddress || getStakingChefAddress()
  const { library } = useActiveWeb3React()
  return useMemo(() => getMasterchefContract(chefAddress, library.getSigner()), [library, chefAddress])
}

export const useVaultStrategy = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getVaultStrategyContract(address, library.getSigner()), [library, address])
}

export const useVaultBank = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getVaultBankContract(library.getSigner()), [library])
}

export const useSousChef = (id) => {
  const { library } = useActiveWeb3React()
  const pool = usePoolFromId(id)
  return useMemo(() => {
    if(pool.sousId === 0)
      return getStakingChefContract(getAddress(pool.contractAddress), library.getSigner())
    return getSouschefContract(id, library.getSigner())
  }, [id, library, pool?.sousId, pool?.contractAddress])
}

export const useSousChefV2 = (id) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSouschefV2Contract(id, library.getSigner()), [id, library])
}

export const usePointCenterIfoContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPointCenterIfoContract(library.getSigner()), [library])
}

export const useBunnySpecialContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnySpecialContract(library.getSigner()), [library])
}

export const useClaimRefundContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getClaimRefundContract(library.getSigner()), [library])
}

export const useTradingCompetitionContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getTradingCompetitionContract(library.getSigner()), [library])
}

export const useEasterNftContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getEasterNftContract(library.getSigner()), [library])
}

export const usePredictionsContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPredictionsContract(library.getSigner()), [library])
}

export const useChainlinkOracleContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getChainlinkOracleContract(library.getSigner()), [library])
}

export const useFPlxContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getFPlxContract(library.getSigner()), [library])
}

export const useFutureContract = (contractAddress: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getFutureContract(contractAddress, library.getSigner()), [library])
}

export const useTokenSaleByUSDCContract = (contractAddress: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getTokenSaleByUSDCContract(contractAddress, library.getSigner()), [library])
}

export const useIAOByUSDCContract = (contractAddress: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIAOByUSDCContract(contractAddress, library.getSigner()), [library])
}

export const useILOContract = (contractAddress: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getILOContract(contractAddress, library.getSigner()), [library])
}

export const useExternalILOContract = (contractAddress: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getExternalILOContract(contractAddress, library.getSigner()), [library])
}


export const useTokenLockerContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getTokenLockerContract(library.getSigner()), [library])
}

export const useLPCompensationContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLPCompensateContract(library.getSigner()), [library])
}

export const useBuyNFT = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBuyNFTContract(library.getSigner()), [library])
}

export const useAirDrop = () => {
  // const { library } = useActiveWeb3React()
  // return useMemo(() => airDropContract(library.getSigner()), [library])
  return useMemo(() => airDropContract(), [])
}

function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      const providerOrSigner = withSignerIfPossible && account ? library.getSigner(account).connectUnchecked() : library
      // return getContract(address, ABI, providerOrSigner)
      return new EContract(address, ABI, providerOrSigner)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWMATICContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WMATIC[chainId].address : undefined, WMATIC_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = ''
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  return useMemo(() => getMulticallContract(), [])
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MERKLE_DISTRIBUTOR_ADDRESS[chainId] : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useGovernanceContract(): Contract | null {
  return useContract(GOVERNANCE_ADDRESS, GOVERNANCE_ABI, true)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function useUniContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? UNI[chainId].address : undefined, UNI_ABI, true)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}
