/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import isArchivedPid from 'utils/farmHelpers'
import fetchFarms from './fetchFarms'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
  fetchUnfrozenDepositTime, fetchAllFarmUserData,
} from './fetchFarmUser'
import { FarmsState, Farm, AppThunk } from '../types'
import { ethers } from 'ethers'

const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid))

const noAccountFarmConfig = farmsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
    unfrozenDepositTime: 0
  },
}))

const initialState: FarmsState = { data: noAccountFarmConfig, loadArchivedFarmsData: false, userDataLoaded: false }

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f.id === farm.id)
        return { ...farm, ...liveFarmData }
      })
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { id } = userDataEl
        const index = state.data.findIndex((farm) => farm.id === id)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    },
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
})

// Actions
export const { setFarmsPublicData, setFarmUserData, setLoadArchivedFarmsData } = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = (): AppThunk => async (dispatch, getState) => {
  const fetchArchived = getState().farms.loadArchivedFarmsData
  const farmsToFetch = fetchArchived ? farmsConfig : nonArchivedFarms
  const farms = await fetchFarms(farmsToFetch)
  dispatch(setFarmsPublicData(farms))
}

export const fetchFarmUserDataAsync = (account: string, signer?: ethers.Signer | ethers.providers.Provider): AppThunk => async (dispatch, getState) => {
  const fetchArchived = getState().farms.loadArchivedFarmsData
  const farmsToFetch = fetchArchived ? farmsConfig : nonArchivedFarms
  // const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch, signer)
  // const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch, signer)
  // const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch, signer)
  // const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch, signer)
  // const userUnfrozenDepositTimes = await fetchUnfrozenDepositTime(account, farmsToFetch, signer)
  //
  // const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
  //   return {
  //     id: farmsToFetch[index].id,
  //     pid: farmsToFetch[index].pid,
  //     allowance: userFarmAllowances[index],
  //     tokenBalance: userFarmTokenBalances[index],
  //     stakedBalance: userStakedBalances[index],
  //     earnings: userFarmEarnings[index],
  //     unfrozenDepositTime: userUnfrozenDepositTimes[index]
  //   }
  // })
  const arrayOfUserDataObjects = await fetchAllFarmUserData(account, farmsToFetch)

  dispatch(setFarmUserData({ arrayOfUserDataObjects }))
}

export default farmsSlice.reducer
