import random from 'lodash/random'

const allNodes = [...Array(7)].map((_, i) => process.env['REACT_APP_NODE_' + i])
// Array of available nodes to connect to
export const nodes = allNodes.filter(node => !!node)

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  console.log('getNodeUrl', nodes[randomIndex])
  return nodes[randomIndex]
}

export default getNodeUrl
