/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import vaultsConfig from 'config/constants/vaults'
import fetchVaults from './fetchVaults'

import {
  fetchAllVaultUserData,
} from './fetchVaultUser'
import { VaultsState, Farm, AppThunk } from '../types'
import { ethers } from 'ethers'

const noAccountFarmConfig = vaultsConfig.map((vault) => ({
  ...vault,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
    unfrozenDepositTime: 0
  },
}))

const initialState: VaultsState = { data: noAccountFarmConfig, loadArchivedFarmsData: false, userDataLoaded: false }

export const vaultsSlice = createSlice({
  name: 'Vaults',
  initialState,
  reducers: {
    setVaultsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f.id === farm.id)
        return { ...farm, ...liveFarmData }
      })
    },
    setVaultUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { id } = userDataEl
        const index = state.data.findIndex((farm) => farm.id === id)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    },
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
})

// Actions
export const { setVaultsPublicData, setVaultUserData, setLoadArchivedFarmsData } = vaultsSlice.actions

// Thunks
export const fetchVaultsPublicDataAsync = (): AppThunk => async (dispatch, getState) => {
  // const fetchArchived = getState().vaults.loadArchivedFarmsData
  const farmsToFetch = vaultsConfig
  const farms = await fetchVaults(farmsToFetch)
  dispatch(setVaultsPublicData(farms))
}

export const fetchVaultUserDataAsync = (account: string, signer?: ethers.Signer | ethers.providers.Provider): AppThunk => async (dispatch, getState) => {
  const farmsToFetch = vaultsConfig

  const arrayOfUserDataObjects = await fetchAllVaultUserData(account, farmsToFetch)

  dispatch(setVaultUserData({ arrayOfUserDataObjects }))
}

export default vaultsSlice.reducer
