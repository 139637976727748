import poolsConfig from 'config/constants/pools'
import masterChefABI from 'config/abi/masterchef.json'
// import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { simpleRpcProvider } from '../../utils/providers'

const pools = poolsConfig//.filter((p) => p.sousId !== 0)

const stakingChefContract = new ethers.Contract(getAddress(pools[0].contractAddress), masterChefABI, simpleRpcProvider)

export const fetchPoolsAllowance = async (account) => {
  const calls = pools.map((p) => ({
    address: getAddress(p.stakingToken.address),
    name: 'allowance',
    params: [account, getAddress(p.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls) || []
  return pools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.id]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  const calls = pools.map((p) => ({
    address: getAddress(p.stakingToken.address),
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(erc20ABI, calls) || []
  const tokenBalances = pools.reduce(
    (acc, pool, index) => ({ ...acc, [pool.id]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )
  return { ...tokenBalances }
}

export const fetchUserStakeBalances = async (account) => {
  const calls = pools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [p.sousId, account],
  }))
  const userInfo = await multicall(masterChefABI, calls)
  const stakedBalances = pools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.id]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  return { ...stakedBalances}
}

export const fetchUserPendingRewards = async (account) => {
  const calls = pools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [p.sousId, account],
  }))
  const res = await multicall(masterChefABI, calls)
  const pendingRewards = pools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.id]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )


  return { ...pendingRewards}
}
