import React, { lazy, useEffect } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { ResetCSS } from '@polydex/uikit';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

// import 'antd/dist/antd.css';
import 'assets/scss/app.scss'
import useEagerConnect from 'hooks/useEagerConnect';
import GlobalStyle from './style/Global';
import history from './routerHistory';
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import ToastListener from './components/ToastListener';
import PageLoader from './components/Loader/PageLoader';
import EasterEgg from './components/EasterEgg';
import Popups from './components/Popups';

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Swap = lazy(() => import('./views/Swap'));
const NotFound = lazy(() => import('./views/NotFound'));

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  useEagerConnect();
  const { account } = useWeb3React();

  useEffect(() => {
    if (!account) return;

  }, [account]);

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      {/* <Menu> */}
      <Popups />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route exact strict path="/swap" component={Swap} />
          <Route path="/" exact component={Swap} />

          {/* 404 */}
          <Route component={NotFound} />
        </Switch>
      </SuspenseWithChunkError>
      {/* </Menu> */}
      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  );
};

export default React.memo(App);
