import BigNumber from 'bignumber.js';

import { BIG_TEN, BIG_ZERO } from './bigNumber';

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: number | string | BigNumber, decimals = 18) => {
  const bnumber =
    amount && BigNumber.isBigNumber(amount) && !!amount.isNaN
      ? amount.isNaN()
        ? BIG_ZERO
        : amount
      : new BigNumber(amount?.toString() || 0);
  return new BigNumber(bnumber).times(BIG_TEN.pow(decimals)).decimalPlaces(0, BigNumber.ROUND_DOWN);
};

export const getBalanceAmount = (amount: number | string | BigNumber, decimals = 18) => {
  const bnumber =
    amount && BigNumber.isBigNumber(amount) && !!amount.isNaN
      ? amount.isNaN()
        ? BIG_ZERO
        : amount
      : new BigNumber(amount?.toString() || 0);
  return new BigNumber(bnumber).dividedBy(BIG_TEN.pow(decimals)).decimalPlaces(decimals, BigNumber.ROUND_HALF_UP);
};

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: number | string | BigNumber, decimals = 18) => {
  const bnumber =
    balance && BigNumber.isBigNumber(balance) && !!balance.isNaN
      ? balance.isNaN()
        ? BIG_ZERO
        : balance
      : new BigNumber(balance?.toString() || 0);
  return getBalanceAmount(bnumber, decimals).toNumber();
};

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, decimalsToAppear?: number) => {
  return getBalanceAmount(balance, decimals).toFixed(decimalsToAppear);
};

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  };
  return number.toLocaleString(undefined, options);
};

export function getBalance(balance: string, decimals = 18): number {
  return getBalanceAmount(balance).toNumber();
}

export function formatNumberAmount(n: number, displayDecimal = 2) {
  if (typeof n === 'string') n = parseFloat(n);

  if (!n) return n;
  const order = Math.floor(Math.log10(n) / 3);
  if (order < 0) {
    return n.toFixed(displayDecimal);
  }

  const units = ['', 'K', 'M', 'B', 'T', 'Q'];
  const num = n / 1000 ** order;
  return units[order] !== undefined ? num.toFixed(displayDecimal) + units[order] : '∞';
}

export function abbreviateNumber(n: number, decimals = 4) {
  const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'Q'];

  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(n)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier <= 0) return n.toFixed(decimals);

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = n / scale;

  // format number and add suffix
  return scaled.toFixed(decimals) + suffix;
}
