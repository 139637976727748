import { Contract, ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'

// Addresses
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getKRXAddress,
  getLotteryAddress,
  getLotteryTicketAddress,
  // getMasterChefAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getCakeVaultAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getfPolyAddress,
  getStakingChefAddress,
  getTokenLockerAddress,
  getLpCompensateAddress,
  getBuyNFTAddress,
  airDropAddress,
  getMulticallAddress, getRouterAddress,
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryAbi from 'config/abi/lottery.json'
import clxAbi from 'config/abi/plxv2.json'
import lotteryTicketAbi from 'config/abi/lotteryNft.json'
import compensationAbi from 'config/abi/compensation.json'
import masterChef from 'config/abi/masterchef.json'
import vaultBankABI from 'config/abi/vaultBank.json'
import vaultStrategyABI from 'config/abi/vaultStrategy.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import easterNftAbi from 'config/abi/easterNft.json'
import cakeVaultAbi from 'config/abi/cakeVault.json'
import predictionsAbi from 'config/abi/predictions.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import fPlxAbi from 'config/abi/fplx.json'
import rewardRefAbi from 'config/abi/rewardRef.json'
import swapRefAbi from 'config/abi/swapRef.json'
import masterChefRefAbi from 'config/abi/masterChefRef.json'
import tokenSalePolygonAbi from 'config/abi/tokenSalePolygon.json'
import IDOAbi from 'config/abi/IAO.json'
import ILOAbi from 'config/abi/ILO.json'
import externalILOAbi from 'config/abi/externalILO.json'
import tokenLockerAbi from 'config/abi/tokenLocker.json'
import buyNFTABI from 'config/abi/buyNFT.json'
import airDrop from 'config/abi/airDrop.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import addresses from 'config/constants/contracts'


import { abi as PolydexRouterABI } from 'config/constants/abis/PolyDexRouter.json'

// expanded to web3 options
type ContractExpansion = { options: { address: string } } & Contract

export const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider): ContractExpansion => {
  const signerOrProvider = signer ?? simpleRpcProvider
  const contract: any = new ethers.Contract(address, abi, signerOrProvider)
  if (contract) {
    contract.options = Object.assign({}, contract.options || {}, { address })
  }
  return contract
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getRewardRefContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(rewardRefAbi, address, signer)
}
export const getSwapRefContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(swapRefAbi, address, signer)
}
export const getMasterChefRefContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChefRefAbi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.id === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer)
}
export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer)
}
export const getPointCenterIfoContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getKRXAddress(), signer)
}
export const getProfileContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer)
}
export const getPancakeRabbitContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer)
}
export const getBunnyFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer)
}
export const getBunnySpecialContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer)
}
export const getLotteryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryAbi, getLotteryAddress(), signer)
}

export const getKRXContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(clxAbi, getKRXAddress(), signer)
}

export const getLotteryTicketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryTicketAbi, getLotteryTicketAddress(), signer)
}

export const getLPCompensateContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(compensationAbi, getLpCompensateAddress(), signer)
}

export const getMasterchefContract = (chefAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, chefAddress, signer)
}

export const getVaultStrategyContract = (chefAddress: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(vaultStrategyABI, chefAddress, signer)
}

export const getVaultBankContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const vaultBankAddress = getAddress(addresses.vaultBank)
  return getContract(vaultBankABI, vaultBankAddress, signer)
}

export const getStakingChefContract = (chefAddress?: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  let masterChefAddress = getStakingChefAddress()
  if(chefAddress){
    masterChefAddress = chefAddress;
  }

  return getContract(masterChef, masterChefAddress, signer)
}
export const getClaimRefundContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer)
}
export const getTradingCompetitionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer)
}
export const getEasterNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer)
}
export const getCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer)
}
export const getPredictionsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer)
}
export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer)
}
export const getFPlxContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(fPlxAbi, getfPolyAddress(), signer)
}
export const getFutureContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(fPlxAbi, address, signer)
}

export const getTokenSaleByUSDCContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tokenSalePolygonAbi, address, signer)
}
export const getIAOByUSDCContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(IDOAbi, address, signer)
}
export const getILOContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ILOAbi, address, signer)
}

export const getExternalILOContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(externalILOAbi, address, signer)
}
export const getTokenLockerContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tokenLockerAbi, getTokenLockerAddress(), signer)
}
export const getBuyNFTContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(buyNFTABI, getBuyNFTAddress(), signer)
}
export const airDropContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(airDrop, airDropAddress(), signer)
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getRouterContract = (_: number, library?: any, account?: string) => {
  // return getContract(PolydexRouterABI, getRouterAddress(), signer)
  return getContract(PolydexRouterABI, getRouterAddress(), account ? library.getSigner(account).connectUnchecked() : library)
}
