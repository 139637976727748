import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { DeFiWeb3Connector } from 'deficonnect';

const POLLING_INTERVAL = 15000;
const RPC_URLS: { [chainId: number]: string } = {
  97: 'https://data-seed-prebsc-1-s1.binance.org:8545/' as string,
  25: 'https://rpc.v3s.finance/' as string,
};

export const injected = new InjectedConnector({ supportedChainIds: [25, 97] });

export const walletconnect = new WalletConnectConnector({
  rpc: {
    25: RPC_URLS[25],
  },
  supportedChainIds: [25],
  chainId: 25,
  qrcode: true,
});

export const deficonnect = new DeFiWeb3Connector({
  rpc: {
    25: RPC_URLS[25],
  },
  supportedChainIds: [25],
  pollingInterval: POLLING_INTERVAL,
});
